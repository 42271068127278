$nav-pills-color: #313131 !default;
$nav-pills-color-active: #D04A02 !default;
$nav-pills-color-disabled: #9e9e9e !default;
$nav-pills-border-color: $nav-pills-color !default;
$nav-pills-border-color-active: $nav-pills-color-active !default;
$nav-pills-border-color-disabled: $nav-pills-color-disabled !default;

.nav-pills {
  margin-bottom: 1rem;

  .nav-link {
    //color: $nav-pills-color;
    border-bottom: 2px solid $nav-pills-border-color;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;

    &.disabled {
      color: $nav-pills-color-disabled;
      border-bottom-color: $nav-pills-border-color-disabled;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: $nav-pills-color-active;
    border-bottom-color: $nav-pills-border-color-active;
  }
}
