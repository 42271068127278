$btn-primary-color: #fff !default;
$btn-primary-bg: #fff !default;
$btn-primary-border-color: #fff !default;
$btn-primary-color-hover: #fff !default;
$btn-primary-bg-hover: #fff !default;
$btn-primary-border-color-hover: #fff !default;

$btn-ghost-color: #D04A02 !default;
$btn-ghost-hover-color: $btn-ghost-color !default;
$btn-ghost-hover-bg: rgba(235, 140, 0, 0.1) !default;
$btn-ghost-hover-border-color: $btn-ghost-color !default;

.btn {
  text-transform: uppercase;
  padding: 14px 10px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 2px;

  &-secondary {
    &,
    &:hover,
    &:focus,
    &:active,
    &:disabled,
    &.disabled {
      color: #fff;
    }
  }

  &-ghost {
    background-color: transparent;
    border: 1px solid transparent;
    color: $btn-ghost-color;
    text-transform: uppercase;
    padding: 7px !important;
    height: 40px !important;
    line-height: 24px;
    vertical-align: middle;
    border-radius: 2px !important;

    &:hover,
    &:focus,
    &:active {
      color: $btn-ghost-hover-color;
      background-color: $btn-ghost-hover-bg;
      border-color: $btn-ghost-hover-border-color;
    }

    &-icon {
      max-height: 24px;
      max-width: 26px;
    }
  }

  &-action {
    min-width: 70px;
  }
}
