.document-metadata {
  .card {
    box-shadow: none;

    &-header {
      padding: 0;

      .btn {
        width: 100%;
        text-align: left;
        color: #2d2d2d;
        font-weight: 500;

        &:hover,
        &:focus {
          text-decoration: none;
        }

        .document-metadata-show-open,
        .document-metadata-show-closed {
          display: none;
        }

        &[aria-expanded=true] {
          .document-metadata-show-open {
            display: inline-block;
          }
        }

        &[aria-expanded=false] {
          .document-metadata-show-closed {
            display: inline-block;
          }
        }
      }
    }
  }

  dl {
    > dt {
      color: #7d7d7d;
      font-weight: normal;
    }
  }
}
