#sphere-selector-toggle {
  transition: all 0.2s ease-in-out;
}
.dropdown.show #sphere-selector-toggle {
  color: $primary;

  &::after {
    transform: rotate(-180deg);
  }
}
