#document-detail {
  .nsm-dialog {
    max-width: 100vw;
    height: 100vh;
    flex: 1 0 auto;
  }

  .modal-body {
    max-height: 90vh;
  }

  div[modal-body] {
    height: calc(100vh - 178px);
  }

  .metadata {
    &-label {
      line-height: 40px;
      font-weight: normal;
      color: #464646;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-label-error {
      line-height: 40px;
      font-weight: normal;
      color: #73170A;
      letter-spacing: 1px;
    }

    &-value,
    &-label {
      margin-bottom: 1rem;
    }
  }
}
