.form-control.form-input.page-number-input {
  padding: 6px;
  max-width: 46px;
  text-align: right;
  font-weight: bold;
  background-color: #f5f5f5;
  color: #424242;
  box-shadow: none!important;
  border-radius: 2px;
}

.page-number-selector {
  background-color: #fff;
  padding: 4px;
}
