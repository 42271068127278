.trigger-hover {
  &:not(:hover):not(:focus):not(:disabled) .visible-hover {
    display: none;
  }

  &:disabled .hidden-hover {
    display: none;
  }

  &:hover,
  &:focus {
    .hidden-hover {
      display: none;
    }

    //.visible-hover {
    //  display: inline-block;
    //}
  }
}
