.drop-zone {
  width: 100%;
  padding: 10px;
  margin: 0 0 0 10px;
  border: dashed 2px #B5B5B5;
  cursor: pointer;
  background: #F5F5F5;

  .drop-text {
    width: 100%;
    text-align: center;
    font-style: italic;
    text-transform: uppercase;
  }
}

.nv-file-over {
  border: dashed 2px #AD1B02;
}

.upload-icon {
  height: 35px;
}

.upload-input {
  display: none !important;
}
