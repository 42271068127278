#law-conservation-modal {
  .nsm-dialog {
    max-width: 80vw;
    width: 100%;
  }

}

a.law-conservation-badge {
  padding: 0 2px 2px;
  background-color: #eb8c00;
  border-radius: 10px;
  margin-left: 5px;

  .badge {
    color: white;
  }
}

.law-conservation-edit-modal .nsm-dialog {
  max-width: 60vw;

  & input[type="text"], ng-select {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .law-conservation-edit-modal .nsm-dialog {
    max-width: 70vw;
  }
}

@media (max-width: 992px) {
  .law-conservation-edit-modal .nsm-dialog {
    max-width: 80vw;
  }
}

@media (max-width: 768px) {
  .law-conservation-edit-modal .nsm-dialog {
    max-width: 90vw;
  }

}
