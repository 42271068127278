$select-box-shadow: 0 0 9px 0 #d4d4d4 !default;
$select-box-shadow-focus: 0 0 2px 0 #D04A02 !default;

.ng-dropdown-panel {
  z-index: 10000 !important;
}

.ng-select {
  &.ng-select-focused:not(.ng-select-disabled) {
    & .ng-select-container {
      border: 1px solid #D04A02 !important;
    }
  }

  //&:not(.no-shadow) {
  //  .ng-select-container {
  //    border: none;
  //    transition: box-shadow 0.2s ease-in-out;
  //
  //    &,
  //    &:hover {
  //      box-shadow: $select-box-shadow;
  //    }
  //  }
  //}
  //
  //&.ng-select-opened,
  //&.ng-select-focused {
  //  &:not(.no-shadow) {
  //    &,
  //    &.ng-select-bottom {
  //      > .ng-select-container {
  //
  //        &,
  //        &:hover {
  //          border: none !important;
  //          box-shadow: $select-box-shadow-focus;
  //        }
  //      }
  //    }
  //  }
  //}
}
