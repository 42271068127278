@import "variables";

#content-login {
  width: 100%;
}
#content {
  /*width: 100%;*/
  width: calc(100% - 250px) !important;
}

.page-container {
  padding: 25px 10px
}


.page-container .breadcrumb {
  background: transparent;
  padding: 0;
}

.page-container .breadcrumb-item + .breadcrumb-item::before {
  /*font-family: 'FontAwesome'; */
  content: ">" !important;
}

.page-container .card .card-body {
  /* padding: 0.5rem 1rem; */
}


/*@media (min-width: 996px) {
    .page-container .card.filter-card {
        width:  680px;
    }
}*/

.page-container .card.filter-card {
  /* max-width:  1000px; */
  display: inline-block;
}

.page-container .card.filter-card.full-width {
  /* max-width:  1000px; */
  display: block;
}

.page-container .card.filter-card button {
  margin-left: 20px;
}

.page-container .card.toolbar-card {
  display: inline-block;

  a {
    color: $link-color;

    &:hover,
    &:focus {
      color: $link-hover-color;
    }
  }
}

.page-container .card .item {
  padding: 5px 0 15px 0
}

.page-container .card.data-model-card {
  margin-bottom: 20px;
}

.page-container .card.data-model-card .card-header {
  color: #73170A;
  padding-left: 1rem;
  right: 1rem;
  font-weight: bold;
}

.btn-success {
  background: #68C120;
  border: #68C120;
}

.btn-undo {
  color: #fff;
  background: #808080;
  border: #808080;
}

.outcome-true,
.outcome-false {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin: 0 15px 0 0;
}

.icon-small-table {
  height: auto;
  width: 12px;
  display: inline-block;
}

.pointer {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.toolbar-box-border-right {
  border-right: 1px solid #B5B5B5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}


