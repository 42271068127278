#documentFilterModal {
  .nsm-dialog {
    max-width: 50vw;
  }

  .modal-body {
    height: 50vh;
  }

  .metadata {
    &-label {
      line-height: 40px;
      font-weight: normal;
      color: #464646;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-label-error {
      line-height: 40px;
      font-weight: normal;
      color: #73170A;
      letter-spacing: 1px;
    }

    &-value,
    &-label {
    }
  }
}
