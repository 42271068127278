$checkbox-bg: #f7f7f7 !default;
$checkbox-bg-checked: #d04a02 !default;
$checkbox-border-color: #eaeaea !default;
$checkbox-border-color-checked: #d04a02 !default;
$checkbox-size: 25px !default;

.datatable {
  &-icon {
    max-height: 20px;
  }
}

.cdk-column {
  &-selected {
    .custom-checkbox {
      .custom-control-label {
        &::before {
          width: $checkbox-size;
          height: $checkbox-size;
          background-color: $checkbox-bg;
          border: 1px solid $checkbox-border-color;
          border-radius: 2px;
        }

        &::after {
          width: $checkbox-size;
          height: $checkbox-size;
        }
      }

      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $checkbox-bg-checked;
        $border-color: $checkbox-border-color-checked;
      }
    }
  }
}

.table {
  td.cdk-cell,
  th.cdk-header-cell {
    padding: 1rem 0.75rem;
    line-height: 25px;
  }
}

.card-body.card-table.sticky-container {
  overflow: auto;
}

.table-striped tbody tr:nth-child(odd) {
  &,
  td.cdk-table-sticky {
    background-color: #f2f2f2;
  }
}

.table-striped tbody tr:nth-child(even) {
  &,
  td.cdk-table-sticky {
    background-color: #ffffff;
  }
}

.table-striped tbody tr:hover {
  &,
  td.cdk-table-sticky {
    background-color: #ececec;
  }
}
