#attachment-modal {
  .modal-content {
    &.nsm-dialog {
      max-width: 800px;
    }

    .modal-body {
      min-height: 50vh;
    }
  }
}
