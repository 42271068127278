@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";

@import "node_modules/ngx-smart-modal/styles/ngx-smart-modal";

@import "node_modules/angular-calendar/css/angular-calendar.css";

@import "main";
@import "layout";
@import "common";
@import "content";
@import "buttons";
@import "forms";
@import "table";
@import "tabs";
@import "modal";
@import "upload";
@import "documents";
@import "utils";

@import "components";
