@import "variables";

.baloon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: #ffffff;
  padding: 6px;
  cursor: pointer;
  text-align: center;
  background-color: $brand-primary;

  &::after {
    display: none!important;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  color: #2d2d2d;
  background-color: #F4F4F4;
}
