@import "variables";

label {
  color: #000000;
  font-size: 13px;
  margin-right: 10px;
}

select {
  height: calc(1.5em + 4px) !important;
  font-size: 14px !important;
  color: $brand-primary !important;
  border-radius: 0.3rem !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-control-input {
  &,
  & + .custom-control-label {
    cursor: pointer;
  }

  &[disabled],
  &.disabled {
    &,
    & + .custom-control-label {
      cursor: not-allowed;
    }
  }
}

.form-control:focus {
  border-color: rgb(206, 212, 218);
  box-shadow: none;
}

label[for="filterNameText"],
label[for="filterDefaultSelect"],
label[for="filterTypeSelect"] {
  margin-right: 10px;
}

label[for="filterTypeSelect"] {
  margin-right: 10px;
}

.form-control {
  height: calc(1.5em + 2px);
  font-size: 14px;
  color: $brand-primary;
  padding-left: 8px;
  padding-right: 8px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea {
  background-color: #f5f5f5;
  //color: #9E9E9E;
  border: 1px solid transparent;

  &:focus {
    background-color: #fff;
    border-color: $brand-primary;
  }
}

input.form-control:disabled, input.form-control[readonly] {
  color: #000000 !important;
  background: #ffffff;
}

.form-control#tipologia {
  min-width: 180px;
}

.form-control#nome {
  min-width: 250px;
}

button {
  border-radius: 2px;
  //height: calc(1.5em + 4px) !important;
  line-height: 12px;
}

input[type="text"],
ng-select {
  margin-right: 20px;
}

.ngb-dp-weekday {
  color: $primary !important;
}

.path {
  font-style: italic;
  color: #9e9e9e;
}

.ng-select.custom {
  border: 0;
  min-height: 0;
  /*border-radius: 0;*/
  min-width: 250px;
  text-transform: uppercase;
  font-size: 14px;

  &[name="filterDefaultSelect"] {
    min-width: 80px;
  }

  &[name="filterStatusSelect"] {
    min-width: 150px;
  }

  &[name="filterHideEmptySelect"],
  &[name="filterHideEmptySelect1"],
  &[name="editDefaultSelect"] {
    min-width: 80px;
  }

  &[name="editYearSelect"] {
    min-width: 100px;
  }

  .ng-select-container {
    min-height: 0;
    /*border-radius: 0;*/
    height: 25px;
  }

  ::ng-deep {
    .ng-select-container {
      min-height: 0;
      border-radius: 0;
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        height: 25px;

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          height: 25px;

          input {
            padding-left: 0 !important;
          }
        }

        .ng-value {
          height: 25px;

          .ng-value-label {
            color: $brand-primary;
          }
        }
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      & > .ng-select-container {
        border-color: rgb(206, 212, 218);
        box-shadow: none;
      }
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        padding: 5px 10px;

        &-label {
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

ng-select.is-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  // box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

ng-select.is-invalid ~ .invalid-feedback {
  display: block !important;
}

.invalid-feedback {
  margin: 0 0 0.25em 0.1em;
  position: absolute;
  width: auto;
  display: block !important;
}

.index-info-card {
  .alt-row {
    background: #F5F5F5;
  }

  .info-elem {
    margin: 15px 20px;
  }
}

.budget-col-year {
  width: 150px !important;
  text-align: center;
}

.btn-clear-filter {
  height: 14px !important;

  img {
    height: 14px !important;
  }
}

.card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  border-radius: 0.3rem !important;
}

.card .card-header,
.card .card-footer {
  background: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
}

.card .card-header h5 {
  margin-bottom: 0;
}

.card.card-simulation .card-header h5 {
  margin-left: 90px;
  color: #B5B5B5;
}

.card.card-simulation .card-header button.edit-simulation {
  position: absolute;
  left: 20px;
  top: 8px;
}

.card.card-simulation .card-body input {
  margin-right: 10px;
  margin-bottom: 15px;
}

.card.card-simulation .card-body input.size1 {
  width: 50px;
  display: initial;
}

.card.card-simulation .card-body input.size2 {
  display: initial;
  width: 80px;
}

.card.card-simulation .card-body input.size3 {
  display: initial;
  width: 200px;
}

.card.card-simulation .card-body input.size4 {
  display: initial;
  width: 300px;
}

.card.card-simulation .card-body span {
  margin-right: 10px;
  margin-bottom: 15px;
  display: inline-block;
}

.card.card-simulation .card-body span.full-row {
  display: block;
}

.card.card-simulation .card-body div[level="0"] {
  margin-left: 0;
}

.card.card-simulation .card-body div[level="1"] {
  margin-left: 50px;
}

.card.card-simulation .card-body div[level="2"] {
  margin-left: 100px;
}

.card.card-simulation .card-body div[level="3"] {
  margin-left: 150px;
}

.form-inline {
  white-space: nowrap;
}

ul.model-list {
  margin: 20px 0;
  padding-left: 20px;
}

ul.model-list li {
  list-style: none;
  white-space: nowrap;
}

ul.model-list > li a {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0;
  font-weight: bold;
  white-space: normal;
  margin-right: 20px;
}

ul.model-list li:before {
  content: '';
  display: inline-block;
  margin: 0;
  padding: 8px 15px;
  list-style: none;
  background-image: url(/assets/images/icons/folder.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 16px;
}

ul.model-list li ul li span {
  color: $brand-primary;
  margin-bottom: 10px;
  margin-right: 30px;
}

ul.model-list li ul li:before {
  padding: 8px 10px;
  background-image: url(/assets/images/icons/file.svg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 13px;

}

.editor-bar {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
  margin: 10px 0;
  padding: 10px 0;

}

.editor-bar .btn-edit {
  padding: 0 15px;
  color: $brand-primary;
  font-size: 13px;
  font-weight: bold;
  background: #F5F5F5;
  margin: 0 10px 10px 0;
}

.editor-bar hr {
  border: none;
  border-left: 1px solid rgba(0, 0, 0, .125);
  height: 25px;
  width: 1px;
  margin: 0 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: #ffffff;
  color: $brand-primary;
}


@media (max-width: 1200px) {
  ul.model-list {
    padding-left: 0;
  }
}

@media (max-width: 992px) {
  label[for="filterNameText"],
  label[for="filterDefaultSelect"] {
    margin-left: 0;
  }

  .filter-card {
    label {
      width: 100px;
      justify-content: left !important;
    }
  }

  .page-container .card.filter-card {
    max-width: 450px;
  }
  .page-container .card.filter-card button {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .filter-card {
    label {
      width: 100%;
      margin: 0 0 10px 8px !important;
    }
  }

}

.chart-legend .horizontal-legend li {
  float: left !important;
}

.chart-legend .legend-title-text {
  margin: 15px 0 10px 0;
  font-weight: bold;
  font-size: 13px
}

.card-table {
  padding: 0;

  table thead tr {
    &,
    th[scope="col"] {
      background: #d6d6d6;
      border: none;
      color: $brand-primary;
      font-weight: bold;
      //text-transform: uppercase;
    }
  }

  &.light {
    table th[scope="col"] {
      background: #fff;
    }
  }
}

.nav.nav-pills {
  .nav-link {
    padding: 0.2rem 1rem;
    /* background: transparent;
    color: #B5B5B5; */
    &.active {
      /*background: #B5B5B5;
      color: #ffffff;*/
    }
  }
}

.icon-button {
  height: 10px;
}

.btn-action-icon {
  background: transparent;
  border: none;
  width: 25px;
  padding: 0;
  margin: 0 5px 0 0;

  img {
    height: 20px;
  }
}


.btn-action-icon {
  &.btn-primary:hover {
    color: #000000;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  &.btn-danger:hover {
    color: $danger;
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.btn-action-icon.btn-primary:disabled {
  color: #000000;
  background-color: transparent !important;
  border-color: transparent !important;
}


.infomation-set-forms-card {
  .question {
    .question-number {
      color: $primary;
      margin: 0 30px 0 0;
    }

    .question-text {

    }

    .response-text {
      margin: 30px 0 0 0;

      textarea {
        width: 100%;
        color: $primary;
      }
    }

    .response {
      min-width: 200px;

      .btn-survey-ok {
        background: transparent;
        color: $brand-success;
        border: 1px solid $brand-success;

        &.checked {
          background: #d6d6d6;
        }
      }

      .btn-survey-ko {
        background: transparent;
        color: $primary;

        &:hover {
          background: transparent !important;
          color: $primary;
        }

        &.checked,
        &.checked:hover {
          background: #d6d6d6 !important;
        }
      }
    }
  }
}

input.input-with-border[type="text"],
input.input-with-border[type="number"],
input.input-with-border[type="password"],
input.input-with-border[type="email"],
input.input-with-border[type="tel"],
textarea.form-control.form-input.input-with-border {
  border: 1px solid #ced4da !important;
  background-color: #FFF;
}

input.input-with-border[type="text"]:focus,
input.input-with-border[type="number"]:focus,
input.input-with-border[type="password"]:focus,
input.input-with-border[type="email"]:focus,
input.input-with-border[type="tel"]:focus,
textarea.form-control.form-input.input-with-border:focus {
  border-color: #D04A02 !important;
}

input.input-with-border[type="text"]:disabled,
input.input-with-border[type="number"]:disabled,
input.input-with-border[type="password"]:disabled,
input.input-with-border[type="email"]:disabled,
input.input-with-border[type="tel"]:disabled,
textarea.form-control.form-input.input-with-border:disabled {
  background-color: #f9f9f9;
}

textarea.form-control.form-input.input-with-border {
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.25rem;
}
