$modal-link-color: #D04A02 !default;
$modal-link-color-hover: #D04A02 !default;

.nsm-dialog {
  min-height: 150px;
}

.modal-content {
  .nsm-content {
    margin: 0;
    padding: 0;
    box-shadow: none;

    a:not(.nav-link) {
      color: $modal-link-color;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        color: $modal-link-color-hover;
      }
    }

    .drop-zone {
      cursor: default;
    }
  }
}

.nsm-overlay-open {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed!important;
}

.nsm-dialog-btn-close {
  padding: .5rem;
}

.modal-body {
  max-height: 60vh;
  overflow-y: auto;
}
