/* You can add global styles to this file, and also import other style files */
@import "node_modules/@pwc-ecobonus/ui/sass/styles";
@import "node_modules/ngx-smart-modal/styles/ngx-smart-modal";
@import 'node_modules/ngx-toastr/toastr-bs4-alert';
@import 'node_modules/ngx-spinner/animations/ball-clip-rotate-multiple.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
  font-family: "open sans", sans-serif;
}

.invalid-feedback {
  position: initial;
  padding-top: 5px;
}

.invalid-feedback-large {
  font-size: larger;
}

.card-overflow {
  overflow: visible !important
}

.ng-invalid.ng-touched {
  box-shadow: none !important;
}

.inline-feedback {
  margin-left: 14px;
}

.btn-label {
  margin-left: 20px;
}

.btn-ghost-icon {
  width: 26px;
}

.no-wrap-label {
  word-break: keep-all;
  white-space: nowrap;
}

.card-like-container {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
}


input.input-with-border[type="text"],
input.input-with-border[type="number"],
input.input-with-border[type="password"],
input.input-with-border[type="email"],
input.input-with-border[type="tel"],
textarea.input-with-border {
  border: 1px solid #ced4da !important;
  background-color: #FFF;
}

input.input-with-border[type="text"]:focus,
input.input-with-border[type="number"]:focus,
input.input-with-border[type="password"]:focus,
input.input-with-border[type="email"]:focus,
input.input-with-border[type="tel"]:focus,
textarea.input-with-border:focus {
  border-color: #D04A02 !important;
}

input.input-with-border[type="text"]:disabled,
input.input-with-border[type="number"]:disabled,
input.input-with-border[type="password"]:disabled,
input.input-with-border[type="email"]:disabled,
input.input-with-border[type="tel"]:disabled,
textarea.input-with-border:disabled {
  background-color: #f9f9f9;
}

textarea.edit-area.form-control.form-input {
  padding: 4px;
  background-color: white;
  border: 1px solid #ced4da !important;
  line-height: unset;
}

th[sortable].desc:before,
th[sortable].asc:before {
  display: none;
}


.filter-item {
  min-width: 120px;
  margin-right: 20px;
  width: 200px;
}

.filter-item-larger {
  min-width: 140px;
  margin-right: 20px;
  width: 220px;
}


.dropdown-menu {
  min-width: 230px;
}

/* make the ng-select as tall as input compoenents for better looks */
.ng-select-container {
  height: 42px !important;
}

.dropdown-toggle::after {
  display: none;
}

.status-badge {
  width: 90px;
  padding: 6px;
  color: white;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.badge-warning {
  background-color: #cca71a;
}

.badge-light {
  background-color: #ACACAC;
}


.table td.cdk-cell,
.table th.cdk-header-cell {
  vertical-align: middle;
  ;
}

form.ng-invalid.ng-touched {
  box-shadow: none !important;
}

.followup-icon {
  width: 20px;
}

.attachment-border {
  height: 100%;
  margin-left: 20px;
}

.ng-select.company-select .ng-arrow-wrapper .ng-arrow {
  display: none;
}

.ng-select.company-select {
  width: auto !important;
}

.ng-select.company-select span.ng-arrow-wrapper:before {
  content: "";

  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;

  margin-left: .255em;
  vertical-align: .255em;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.ng-select.auto-grow {
  display: flex;

  .ng-select-container {
    min-width: 100px;
    width: auto;
  }

  .ng-dropdown-panel {
    width: auto;
  }
}

.ng-select.company-select .ng-select-container {
  width: auto !important
}


.ng-select.company-select .ng-select-container,
.ng-select.company-select .ng-select-container:hover,
.ng-select.company-select .ng-select-container:focus {
  color: white;
  border: none;
  /*border-bottom: 1px solid white;*/
  box-shadow: none !important;
  min-height: 0px;
  border-radius: 0;
  width: 250px;
  background-color: transparent;
  -webkit-box-shadow: none;
}

.ng-select.company-select .ng-control {
  min-height: 0px;
  border-radius: 0;
}

.form-inline label {
  align-items: start;
  justify-content: start;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.section-title h3 {
  color: #d04a02;
}

.section-title label {
  text-transform: uppercase;
  ;
}

.baloon {
  white-space: nowrap;
  line-height: 18px;
  vertical-align: middle;
}

.form-input-group.form-inline {
  .form-input-label {
    @extend .mb-0;
  }

  .form-input {
    width: 100%;
  }
}

.card {
  border-radius: 0 !important;

  &>.card-header {
    background-color: #D6D6D6;
    color: #2D2D2D;
    text-transform: uppercase;
    border-radius: 0 !important;
    border-bottom: none;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

}

.nav-link {
  color: black !important;
  text-transform: uppercase;
}

.nav-link.active {
  color: #D04A02;
}


input[type="text"],
ng-select,
label {
  margin-right: 0;
}

.form-control.form-input[type="time"] {
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
}

.btn-clear-filter {

  pwc-icon,
  svg {
    max-height: 100%;
  }
}


/*
 * CKEDITOR custom
*/


.document-editor {
  border: 1px solid var(--ck-color-base-border);
  background: rgb(249, 249, 249);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  height: 500px;

  /* This element is a flex container for easier rendering. */
  //display: flex;
  //height: 100%;
  //flex-flow: column nowrap;
  display: block;
  height: calc(21cm + 50px);

  //padding: calc( 2 * var(--ck-spacing-large) );
  background: var(--ck-color-base-foreground);
  overflow-y: scroll;

}

// .cke_editable {
//   /* Set the dimensions of the "page". */
//   width: 15.8cm;
//   min-height: 21cm;
//   height: auto !important;
//   height: 21cm;

//   /* Keep the "page" off the boundaries of the container. */
//   padding: 1.5cm 1cm;

//   border: 1px hsl(0, 0%, 82.7%) solid;
//   background: white;

//   /* The "page" should cast a slight shadow (3D illusion). */
//   box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

//   /* Center the "page". */
//   margin: 20px auto;
// }


// .ck-toolbar {
//   /* Make sure the toolbar container is always above the editable. */
//   z-index: 1;

//   /* Create the illusion of the toolbar floating over the editable. */
//   box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

//   /* Use the CKEditor CSS variables to keep the UI consistent. */
//   border-bottom: 1px solid var(--ck-color-toolbar-border);

//   position: absolute !important;
//   width: calc(100% - 32px) !important;
//   padding: 0 !important;
// }

// /* Adjust the look of the toolbar inside the container. */
// .document-editor__toolbar .ck-toolbar {
//   border: 0;
//   border-radius: 0;
// }

// /* Make the editable container look like the inside of a native word processor application. */
// .document-editor__editable-container {
//   padding: calc(2 * var(--ck-spacing-large));
//   background: var(--ck-color-base-foreground);

//   /* Make it possible to scroll the "page" of the edited content. */
//   overflow-y: scroll;
// }

// .ck-editor__editable {
//   /* Set the dimensions of the "page". */
//   width: 15.8cm;
//   min-height: 21cm;

//   /* Keep the "page" off the boundaries of the container. */
//   padding: 1cm 2cm 2cm;

//   border: 1px hsl(0, 0%, 82.7%) solid;
//   border-radius: var(--ck-border-radius);
//   background: white;

//   /* The "page" should cast a slight shadow (3D illusion). */
//   box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

//   /* Center the "page". */
//   margin: calc(2 * var(--ck-spacing-large) + 40px) auto calc(2 * var(--ck-spacing-large)) auto;
// }

// .ck-content.ck-editor__editable {
//   padding: 25px !important;
// }

.editable-field {
  background: yellow;
}

.editor-tabs {
  .nav-item {
    display: block;
    width: 50%
  }
}

.dropdown-toggle::after {
  display: none;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.operations-tooltip {
  z-index: 99999;

  .tooltip-inner {
    width: 500px;
    max-width: 90vw;
    font-size: 14px;
  }
}

#sidebar {
  .sidebar-header.UNICREDIT {
    background-color: #fff;
  }

  img.logo {
    height: auto !important;
    max-height: 50px;
    max-width: 100%;
  }
}

.expand-button-icon {
  transition: transform .4s linear;
  margin-right: 8px;

  &.expanded {
    transform: rotateX(0deg);
  }

  &:not(.expanded) {
    transform: rotateX(180deg);
  }

  .right {
    transform: rotateX(90deg);
  }
}

.white-control {
  background-color: #fff !important;
}

.selection-modal {
  .nsm-dialog {
    max-width: 90vw;
    height: 90vh;
    flex: 1 0 auto;
  }

  .nsm-body {
    height: 90vh;
  }

  .modal-body {
    height: calc(90vh - 54px);
    max-height: calc(90vh - 54px);
  }

  .modal-footer {
    display: none !important;
  }
}

#uploadValidationModal {
  .nsm-dialog {
    max-width: 90vw;
    height: 90vh;
    flex: 1 0 auto;
  }

  .nsm-body {
    height: 90vh;
  }

  .modal-body {
    height: calc(90vh - 158px);
    max-height: calc(90vh - 158px);
  }

  .modal-footer {
    height: 100px;
  }
}

#uploadValidationEligibilityModal {
  .nsm-dialog {
    max-width: 90vw;
    height: 90vh;
    flex: 1 0 auto;
  }

  .nsm-body {
    height: 90vh;
  }

  .modal-body {
    height: calc(90vh - 158px);
    max-height: calc(90vh - 158px);
  }

  .modal-footer {
    height: 100px;
  }
}

#viewReconciliationAups {
  .nsm-dialog {
    max-width: 1200px !important;
    flex: 1 0 auto;
  }
}

.status-badge {
  word-break: break-word;
  white-space: break-spaces;
  width: 120px;
}

.color-orange {
  color: #EB8C00 !important;
}

.h-scroll {
  overflow-x: auto;
}


label {
  pwc-icon {
    margin-left: 1em;
  }
}

label {
  pwc-icon {
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}


.actions-menu {
  /*z-index: 2000;*/
  overflow: visible;
  margin-top: -18px;
}

.text-black {
  color: black;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.tax-engine-light {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;

  &.light-green {
    background-color: #22992e;
  }

  &.light-grey {
    background-color: #979797;
  }
}

.card {
  &.card-warning {
    @extend .alert-warning;

    .card-text {
      @extend .alert-warning;
    }
  }
}


.file-saved {
  border: 1px solid #EB8C00;
  border-radius: 8px;
  line-height: 32px;
  padding: 5px 10px;

  .file-name {
    max-width: 350px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.legend-labels {
  max-height: 125px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

// BUILDER


.editor-bar .btn-edit {
  padding: 15px;
}

.configurator-element {
  padding: 10px 20px;
  margin: 10px 0px;
  background: #F5F5F5;
}

.dndDragover {
  //background: #F5F5F5;
  border: 2px dashed #eb8c00;
  //opacity: 0.1;
  //margin: 20px;
}

.configurator-element,
.element-label {
  padding-left: .5em;
  padding-right: .5em;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-top: 0px;
  white-space: nowrap;
}

.variable-element,
.outcome-element {
  font-weight: bolder;
}

.outcome-element {
  text-transform: uppercase;
}

.value-element .form-control {
  max-width: 100px;
}

.field-placeholder {
  vertical-align: middle;
  height: 40px;
  width: 40px;
  display: inline-block;
  margin-left: 1rem;
  margin-right: 1rem;
  margin: 1rem;

  &.empty-placeholder {
    background-color: #d6d6d6;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  }

  .placeholder-btn & {
    margin: 0 10px 10px 0;
    display: inline-block;
    height: 2rem;
    transform: none !important;
  }
}

.dndDragover>.empty-placeholder {
  //display: none;
  //background: #eb8c00;
  opacity: 0.5;
}

.trash-list .field-placeholder {
  display: none !important;
}

.trash-placeholder img {
  width: 32px;
  height: 32px;
}

.element-type-LOGIC {
  display: flex !important;
  flex: 0 0 auto;
}

.dndDraggingSource {
  display: none;
  pointer-events: none
}

.dndDraggingSource {
  display: none;
}

.dndPlaceholder {
  &.field-placeholder {
    //display: block;
    background: #eb8c00;
    opacity: 0.3;
  }
}

.value-element {
  input[type=text] {
    border-radius: 2px;
    border: none !important;
  }
}

.element-type-VALUE {
  vertical-align: middle;
}

.section-title {
  width: 100%;
  padding: 14px 0 14px 28px;
  background-color: rgb(234 232 232 / 73%)
}

.section-title h3 {
  color: #d04a02;
}

.section-title label {
  text-transform: uppercase;
}

#linkedProjectInfoModal {
  .nsm-dialog-btn-close {
    display: none
  }
}

#linkedProjectCreationInfoModal {
  .nsm-dialog-btn-close {
    display: none
  }
}

.pointer {
  cursor: pointer;
}

pwc-icon svg :not(.custom-size) {
  width: 20px;
  height: 20px;
}

pwc-icon[name="edit"] {
  path {
    fill: $brand-primary;
  }
}

.copiable {
  @extend .pointer;

  &:hover {
    @extend .text-primary;
  }
}

.project-tab-item {
  @extend .card;
  @extend .m-2;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;

  padding: 0 !important;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  text-align: center;
  box-shadow: none !important;

  a {
    padding: 1rem .5rem;
    width: 230px;
    flex: 1;

    h5 {
      padding-top: 0.5rem;
      color: #000;
    }
  }

  &.arrow-box a h5 {
    color: #D04A02;
  }
}

.text-bg-success {
  background-color: #28a745 !important;
}
