$font-family-sans-serif: "open sans", sans-serif;
$body-bg: #F5F5F5;
$font-size-base: 0.75rem;
$primary: #D04A02;
$secondary: #eb8c00;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee

$brand-primary: $primary;
$brand-secondary: $secondary;
$brand-success: #68C120;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: #fff !default;
//** Global text color on `<body>`.
$text-color: $gray-dark !default;

//** Global textual link color.
$link-color: $brand-secondary;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 7%);
//** Link hover decoration.
$link-hover-decoration: underline !default;

$primary-hover: #7A2B01 !default;
$color-hover: #B64000 !default;

//== Buttons
//$btn-primary-color: #fff !default;
$btn-primary-bg: $link-color;
$btn-primary-border-color: $link-color;
//$btn-primary-color-hover: #fff !default;
$btn-primary-bg-hover: $link-hover-color;
$btn-primary-border-color-hover: $link-hover-color;

$btn-ghost-color: $link-color !default;
$btn-ghost-hover-color: $link-color !default;
$btn-ghost-hover-bg: rgba(235, 140, 0, 0.1) !default;
$btn-ghost-hover-border-color: $btn-ghost-color !default;

//== Forms
//$form-input-box-shadow: 0 0 9px 0 #d4d4d4 !default;
$form-input-box-shadow-focus: 0 0 2px 0 $brand-primary !default;
//$form-input-bg-color: #fafafa !default;
//$form-input-height: 40px !default;
//$form-input-border-radius: 2px !default;
//$form-input-color: #9e9e9e!default;

//== Login
$login-primary-color: $brand-primary;

//== Datatable
//$checkbox-bg: #f7f7f7 !default;
$checkbox-bg-checked: $brand-primary;
//$checkbox-border-color: #eaeaea !default;
$checkbox-border-color-checked: $brand-primary;
//$checkbox-size: 25px !default;

//== File uploader
$modal-link-color: $link-color;
$modal-link-color-hover: $link-hover-color;


//= Tabs - Pills
$nav-pills-border-radius: 0;
$nav-pills-link-active-bg: transparent;
$nav-pills-border-color-active: $primary;
$nav-pills-link-active-color: $primary;
