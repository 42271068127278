$form-input-box-shadow: 0 0 9px 0 #d4d4d4 !default;
$form-input-box-shadow-focus: 0 0 2px 0 #D04A02 !default;
$form-input-box-shadow-invalid: 0 0 2px 1px #d9534f !default;
$form-input-bg-color: #fafafa !default;
$form-input-border-color: #AD1B02;
$form-input-height: 40px !default;
$form-input-border-radius: 2px !default;
$form-input-color: #9e9e9e !default;
$form-input-label-color: #8a8a8a !default;

.form-group.form-input-group {
  margin-bottom: 0;
  position: relative;
}

.form-group.form-input-group.withError {
  margin-bottom: 15px;
  position: relative;
}

.form-input-label {
  @extend .mb-3;
  color: $form-input-label-color;
}

.form-control.form-input {
  color: $form-input-color;
  border: 0 !important;
  border-radius: $form-input-border-radius;
  background-color: $form-input-bg-color;
  padding-top: $form-input-height / 2;
  padding-bottom: $form-input-height / 2;
  line-height: $form-input-height;

  &:focus {
    box-shadow: $form-input-box-shadow-focus;
  }

  &.no-shadow {
    box-shadow: none;
    border: 1px solid transparent;

    &:focus {
      border-color: #D04A02;
    }
  }

  &.mandatory {
    &.no-shadow:not([disabled]) {
      box-shadow: none;
    }

    &:not([disabled]) {
      border: 1px solid $form-input-border-color !important;
    }
  }

  &.is-invalid {
    box-shadow: $form-input-box-shadow-invalid;
  }
}

%form-input-icon {
  background-repeat: no-repeat !important;
  background-position: center left !important;
  background-size: $form-input-height $form-input-height !important;
}

%form-input-prepend-icon {
  @extend %form-input-icon;
  padding-left: $form-input-height !important;
}

%form-input-append-icon {
  @extend %form-input-icon;
  padding-right: $form-input-height !important;
}

.form-control.is-search,
.ng-select.is-search .ng-select-container {
  @extend %form-input-prepend-icon;
  background-image: url('/assets/images/icons/search.svg');
}

.ng-select.is-search {
  .ng-select-container {
    padding-left: 30px !important;
  }

  .ng-input {
    padding-left: 40px !important;
  }
}

.form-control.is-email {
  @extend %form-input-prepend-icon;
  background-image: url('/assets/images/icons/unread.svg') !important;
  background-size: 20px auto !important;
  background-position: 10px center !important;
}

.form-input-icon {
  width: 16px;
  height: auto;
  display: inline-block;

  &.append {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.form-input {
  &-label + & + &-icon,
  &-label + & + .dropdown-menu + &-icon {
    &.append {
      top: calc((13px * 1.5) + 1rem + 12px); // Label font-size * line-height + label margin bottom + centering in input
    }
  }
}

.searchable {
  position: relative;

  input {
    padding-right: 24px;
  }

  &-loading {
    position: absolute;
    right: 1em;
    top: 0.75rem;
  }

  &.has-label {
    .searchable-loading {
      top: 3rem;
      right: 1em;
    }
  }
}

.ngb-tp-input {
  @extend .form-input;
  @extend .input-with-border;
  max-width: 100%;
}

.ngb-tp-input-container .btn-link {
  padding: 7px 10px;
}
