@import "variables";

$sidebarItemMainActive: $primary-hover !default;
$sidebarItemHover: $color-hover !default;
$footer-bg: #DEDEDE !default;
$footer-color: #7D7D7D !default;

#sidebar {
  background-color: $brand-primary;
  color: #fff;

  .sidebar-header {
    background: $brand-primary;
    border-bottom: 1px solid #fff;
  }

  ul {
    li {
      a span {
        max-width: calc(100% - 65px) !important;
      }

      a[aria-expanded="false"] {
        background: url(/assets/images/icons/down-arrow.svg) no-repeat right 6px;
        background-size: 10px 10px;
        background-origin: content-box;
      }

      a[aria-expanded="true"] {
        background: url(/assets/images/icons/up-arrow.svg) no-repeat right 6px;
        background-size: 10px 10px;
        background-origin: content-box;
      }

      &.active > a,
      a:hover {
        color: #FFFFFF;
        background-color: $sidebarItemHover;
      }

      &.mainActive > a {
        color: #fff;
        background-color: $sidebarItemMainActive;
      }
    }

    ul {
      a {
        background-color: $brand-primary;
      }
    }
  }

  .list-2nd-level {
    ul {
      li {
        a {
          background-color: #fff;
          color: #B5B5B5;

          &:hover {
            background-color: #F5F5F5;
            color: #000000;
          }
        }

        &.active {
          a {
            background-color: #F5F5F5;
            color: #000000;
          }
        }
      }
    }
  }

  &.active {
    background-color: $brand-primary;
  }

  .btn-info {
    background-color: transparent;
    border-color: transparent;
  }

  .icon-menu.icon-indicatori {
    background: url(/assets/images/icons/menu-laterale-indicatori.svg) no-repeat center center;
  }
}

#footer {
  background: $footer-bg;
  color: $footer-color;
}

.header .rightHeader .badge {
  background-color: $brand-primary;
}
