p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}
a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    /*transition: all 0.3s;*/
}

.wrapper {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
}

h1, h2, h3, h4, h5 {
    color: $brand-primary;
    font-weight: bold;
}
h3 {
    font-size: 17px;
    font-weight: bold;
}

h5 {
    font-size: 14px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-primary.focus, .btn-primary:focus {
    box-shadow: none;
}
.btn.focus, .btn:focus {
    box-shadow: none !important;
}

main {
  min-height: calc( 100vh - 130px)
}
@media screen and (max-height: 690px)  {
  main {
    min-height: 560px;
  }
}



.page-link {
    border: none !important;
    color: #000000;
}
.page-item.active .page-link {
    background: transparent!important;
    color: #AD1B02;
}
.page-link:focus {
    box-shadow: none;
}
.page-link:hover {
    color: #AD1B02;
}

.page-container .breadcrumb {
    background: transparent;
    padding: 0;
}

.page-container .breadcrumb-item + .breadcrumb-item::before {
    /*font-family: 'FontAwesome'; */
    content: ">" !important;
}






.lds-dual-ring {
    display: inline-block;
    width: 24px;
    height: 24px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #AD1B02;
    border-color: #AD1B02 transparent #AD1B02 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.box-filter {
  input.input-auto-height {
    height: auto;
  }
}
