@import "variables";

.tabsYears {
  color: $brand-primary;
}

.breadcrumb-item {
  color: $brand-primary;

  &:last-of-type {
    color: #4B4B4B;

    a {
      cursor: default;
    }
  }
}

.year-selector {
  color: #9E9E9E;
  border-bottom: 1px solid #B4B4B4;

  &.selected {
    color: $brand-primary;
    border-bottom-color: $brand-primary;
  }
}

.document-info-icon {
  max-width: 30px;
  max-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-right: 1px solid #b5b5b5;

  &:last-of-type {
    border-right: none;
  }
}
