$login-primary-color: #D04A02!default;

.login {
  &-page {
    .custom-select:focus {
      border-color: $login-primary-color;
    }

    h3 {
      color: $login-primary-color;
    }
  }

  &-box {
    .welcome-box {
      h1 {
        color: $login-primary-color;
      }
    }

    a, a:hover, a:focus {
      color: $login-primary-color;
    }
  }
}
